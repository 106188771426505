// 
// 
// ie11.scss
//
//

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
   .card{
    display: block;
   } 
   .media{
    display: block;
   }
}