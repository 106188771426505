// 
// 
// layouts.scss
//
//

.main-container{
  > .container:last-child, >.container-fluid:last-child{
    padding-bottom: $spacer*4;
  }
}

@include media-breakpoint-up(lg) {
  .layout-nav-side{
    display: flex;
    > .navbar{
      padding: $spacer;
      width: 264px;
      height: 100vh;
      overflow-y: scroll;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      flex-direction: column;
      align-items: flex-start;
      .navbar-brand{
        margin-bottom: $spacer*2;
      }
      .navbar-collapse{
        align-items: flex-start;
        width: 100%;
        flex-shrink: 0;
        padding-bottom: $spacer;
      }
      .navbar-nav{
        flex-direction: column;
        width: 100%;
      }
      .nav-link{
        padding-left: 0;
      }
    }
    .main-container{
      overflow: hidden;
      flex: 1;
      max-width: calc(100vw - 264px);
      > .container{
        padding-left: $spacer*2;
        padding-right: $spacer*2;
      }
    }
    .content-container{
      display: flex;
      height: calc(100vh - 3.5625rem);
    }
  }
  .layout-sidebar{
    .main-container{
      display: flex;
      flex-direction: row-reverse;
      height: calc(100vh - 59px);
      overflow: hidden;
      .content-container{
        height: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        flex: 1;
        > .container:last-child{
          padding-bottom: $spacer*3;
        }
      }
    }
  }
}

.main-container{
  > .container{
    padding-bottom: $spacer*3;
  }
}