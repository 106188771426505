// 
// 
// tooltips.scss
//
//

.tooltip{
  font-weight: 500;
}

[data-toggle="tooltip"]{
  position: relative;
}