// 
// 
// avatars.scss
//
//

.avatar{
  width: $spacer*1.5;
  height: $spacer*1.5;
  border-radius: 50%;
  border: 2px solid $body-bg;
  background: $body-bg;
  color: $white;
  &.bg-primary{
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: 1rem;
    }
  }
  &.avatar-lg{
    width: $spacer*2;
    height: $spacer*2;
  }
  &.avatar-sm{
    width: $spacer;
    height: $spacer;
  }
}

@include media-breakpoint-up(lg) {
  .avatar{
    &.avatar-lg{
      width: $spacer*3;
      height: $spacer*3;
    }
  }
}

.card{
  .avatar{
    border-color: $white;
    background: $white;
  }
}

.avatars{
  padding-left: 0;
  list-style: none;
  margin: 0;
  > li{
    display: inline-block;
    + li{
      margin-left: -$spacer/2;
    }
  }
}

.bg-dark{
  .avatar{
    border-color: theme-color('dark');
  }
}