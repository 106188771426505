// 
// 
// utilities.scss
//
//

.no-scroll-bars{
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
}

.fullscreen{
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.shadow-lg{
  box-shadow: 0 1rem 4rem rgba(0,0,0,0.07) !important;
}

.overflow-visible{
  overflow: visible;
}